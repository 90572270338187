<template>
  <div>
    <div>
      <button
        style="margin: 10px 10px 10px 0"
        @click="changePath('/hand-track')"
      >
        切换到手部贴图
      </button>
      <button class="operation-buttons" @click="imgSrc = '/1.jpg'">
        切换图片1
      </button>
      <button class="operation-buttons" @click="imgSrc = '/2.jpg'">
        切换图片2
      </button>
      <button class="operation-buttons" @click="imgSrc = '/3.jpg'">
        切换图片3
      </button>
    </div>
    <div style="display: flex; align-items: center">
      <div ref="box" style="height: 480px; width: 640px; position: relative">
        <video ref="video" width="640" height="480" autoplay></video>
        <canvas ref="canvas" width="640" height="480"></canvas>
      </div>
      <button
        @click="getImage"
        style="margin-left: 700px; width: 50px; height: 100px"
      >
        保存贴图
      </button>
      <!-- 上面自己设置大小 -->
    </div>
  </div>
</template>

<script setup>
  import html2canvas from 'html2canvas'
  import { ref, onMounted } from 'vue'
  import * as handTrack from 'handtrackjs'

  const box = ref(null)
  const imgSrc = ref('/1.jpg')
  const video = ref(null)
  const canvas = ref(null)
  const modelParams = {
    flipHorizontal: true, // flip e.g for video
    maxNumBoxes: 1, // maximum number of boxes to detect
    iouThreshold: 0.5, // ioU threshold for non-max suppression
    scoreThreshold: 0.7, // confidence threshold for predictions.
  }

  onMounted(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: {} })
      video.value.srcObject = stream
      const model = await handTrack.load(modelParams)
      setInterval(detectFace(model), 100)
    } catch (error) {
      console.error('Error accessing the camera: ' + error)
    }
  })

  function changePath(path) {
    window.location.href = path
  }

  function detectFace(model) {
    const videoElement = video.value
    const canvasElement = canvas.value
    const context = canvasElement.getContext('2d')

    return async () => {
      const predictions = await model.detect(videoElement)
      console.log(predictions)
      context.clearRect(0, 0, canvasElement.width, canvasElement.height)
      if (predictions.length > 0) {
        // for (let i = 0; i < predictions.length; i++) {
        predictions.forEach((prediction) => {
          if (prediction.label !== 'face') return
          let face = prediction.bbox
          drawImage(context, face)
        })
        // }
      }
    }
  }

  function drawImage(context, bbox) {
    context.beginPath()
    const img = new Image()
    img.src = imgSrc.value
    context.drawImage(img, bbox[0], bbox[1] + 50, 200, 40)
    context.stroke()
  }

  async function getImage() {
    const canvas = await html2canvas(box.value)

    // 创建一个链接用于下载图片
    const link = document.createElement('a')
    link.href = canvas.toDataURL('image/png')
    link.download = 'content_image.png'

    // 模拟点击链接以触发下载
    link.click()
  }
</script>

<style scoped>
  video,
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }

  canvas {
    transform: scaleX(-1); /* 水平镜像 */
  }

  .operation-buttons {
    margin: 10px;
  }
</style>
