<template>
  <div class="chat-container">
    <div class="chat-header">
      <span>数树AI助手</span>
    </div>
    <div class="chat-body">
      <div v-for="(item, index) in history" :key="index" class="message">
        <span class="question">{{ item.question }}</span>
        <span class="answer">{{ item.answer }}</span>
      </div>
    </div>
    <div class="chat-footer">
      <input
        type="text"
        v-model="questionInput"
        class="input-field"
        placeholder="输入您的问题..."
      />
      <button @click="sendMsg" class="send-button">发送</button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import * as base64 from "base-64";
import CryptoJs from "crypto-js";

const beforeQuestion = ref(
  "你是中国古代数学虚拟数字博物馆的服务AI，名字是树数，用于解决用户的问题。用户的问题是："
);
const afterQuestion = ref("请具体描述。");
const questionInput = ref(null);

const history = ref([]); // 对话历史

let requestObj = {
  APPID: "068f605e",
  APISecret: "ZjExOGU0MzBiZGYzNDFlZjVlMjE0OTMx",
  APIKey: "273a9b2bbf75bace3116f9f3656735c4",
  Uid: "jsj",
};
// 点击发送信息按钮

// 发送消息
const sendMsg = async () => {
  const getWebsocketUrl = () => {
    return new Promise((resolve) => {
      let url = "wss://spark-api.xf-yun.com/v1.1/chat";
      let host = "spark-api.xf-yun.com";
      let apiKeyName = "api_key";
      let date = new Date().toGMTString();
      let algorithm = "hmac-sha256";
      let headers = "host date request-line";
      let signatureOrigin = `host: ${host}\ndate: ${date}\nGET /v1.1/chat HTTP/1.1`;
      let signatureSha = CryptoJs.HmacSHA256(
        signatureOrigin,
        requestObj.APISecret
      );
      let signature = CryptoJs.enc.Base64.stringify(signatureSha);

      let authorizationOrigin = `${apiKeyName}="${requestObj.APIKey}", algorithm="${algorithm}", headers="${headers}", signature="${signature}"`;

      let authorization = base64.encode(authorizationOrigin);

      url = `${url}?authorization=${authorization}&date=${encodeURI(
        date
      )}&host=${host}`;

      resolve(url);
    });
  };
  let myUrl = await getWebsocketUrl();
  let socket = new WebSocket(myUrl);
  history.value.push({
    question: questionInput.value,
    answer: "",
  });
  socket.addEventListener("open", (event) => {
    console.log("开启连接！！", event);
    let params = {
      header: {
        app_id: requestObj.APPID,
        uid: "jsj",
      },
      parameter: {
        chat: {
          domain: "general",
          temperature: 0.5,
          max_tokens: 1024,
        },
      },
      payload: {
        message: {
          text: [
            // { role: 'user', content: '你是谁' }, //# 用户的历史问题
            // { role: 'assistant', content: '我是AI助手' }, //# AI的历史回答结果
            {
              role: "user",
              content: `${beforeQuestion.value}${questionInput.value}${afterQuestion.value}`,
            }, //# 最新的一条问题，如无需上下文，可只传最新一条问题
          ],
        },
      },
    };
    socket.send(JSON.stringify(params));
  });
  socket.addEventListener("message", (event) => {
    let data = JSON.parse(event.data);
    console.log("收到消息！！", data);
    history.value[history.value.length - 1].answer +=
      data.payload.choices.text[0].content;
    if (data.header.code !== 0) {
      console.log("出错了", data.header.code, ":", data.header.message);
      // 出错了"手动关闭连接"
      socket.close();
    }
    if (data.header.code === 0) {
      // 对话已经完成
      if (data.payload.choices.text && data.header.status === 2) {
        console.log("对话已经完成！！", data);
        setTimeout(() => {
          socket.close();
        }, 1000);
      }
    }
  });
  socket.addEventListener("close", (event) => {
    console.log("连接关闭！！", event);
    questionInput.value = "";
  });
  socket.addEventListener("error", (event) => {
    console.log("连接发送错误！！", event);
  });
};
</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 8px;
  background: #1a1a1a; /* Dark background for the chat */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  color: #ddd; /* Light text color for contrast */
  font-family: "Roboto", sans-serif; /* Modern font */
}

.chat-header {
  background: #333; /* Slightly lighter header background */
  color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-bottom: 1px solid #555; /* Add a border for definition */
}

.chat-body {
  height: 520px;
  overflow-y: scroll;
  padding: 2px;
  background-image: url("/public/images/聊天框图.jpg"); /* Set the background image */
  background-size: cover; /* Ensure it covers the chat area */
  background-position: center; /* Center the image in the chat area */
  background-repeat: no-repeat; /* Do not repeat the image */
}

.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.question,
.answer {
  background: #242424; /* Dark speech bubbles */
  color: #ddd; /* Light text for readability */
  padding: 8px;
  border-radius: 4px;
  margin-top: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.question {
  align-self: flex-end;
  border-top-right-radius: 0; /* Style similar to messaging apps */
}

.answer {
  align-self: flex-start;
  border-top-left-radius: 0; /* Style similar to messaging apps */
}

.chat-footer {
  display: flex;
  padding: 8px;
  background: #333; /* Footer background to match the header */
  border-top: 1px solid #555; /* Border for definition */
}

.input-field {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  margin-right: 8px;
  background: #242424; /* Input field styling */
  color: #ddd;
}

.send-button {
  background: #0a84ff; /* A blue color to stand out as the action item */
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-button:hover {
  background: #0077e6; /* Darker blue on hover */
}

.input-field:focus {
  outline: none;
  border: 1px solid #0a84ff; /* Blue border to indicate focus */
}

/* Scrollbar styles */
.chat-body::-webkit-scrollbar {
  width: 8px;
}

.chat-body::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

.chat-body::-webkit-scrollbar-track {
  background: #333;
}
</style>
