<template>
  <HandTrack v-if="currentPath === '/hand-track'" />
  <FaceTrack v-else-if="currentPath === '/face-track'" />
  <SparkAI v-else-if="currentPath === '/spark-ai'" />
</template>

<script setup>
  import { ref } from 'vue'
  import HandTrack from './HandTrack.vue'
  import FaceTrack from './FaceTrack.vue'
  import SparkAI from './SparkAI.vue'
  const currentPath = ref(window.location.pathname)
  if (
    currentPath.value !== '/hand-track' &&
    currentPath.value !== '/face-track' &&
    currentPath.value !== '/spark-ai'
  ) {
    window.onload = function () {
      window.location.href = './src/homepage/homepage.html' // 跳转
    }
  }
</script>

<style>
  #app {
    width: 640px;
  }
</style>
